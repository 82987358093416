var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-row", { attrs: { "no-gutters": "" } }, [
        _c("p", { staticClass: "display-1 mt-2 text-uppercase" }, [
          _vm._v(" " + _vm._s(_vm.$t("title-quotation-detail")) + " "),
        ]),
      ]),
      _c(
        "v-row",
        { staticClass: "mb-4", attrs: { "no-gutters": "", align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 8 } },
            [
              _c("div", { staticClass: "mr-4" }, [
                _c("b", [_vm._v(_vm._s(_vm.$t("row-quotation-code")))]),
                _c("span", [_vm._v(": " + _vm._s(_vm.myQuotation.code))]),
              ]),
              _c("div", { staticClass: "d-flex flex-wrap" }, [
                _c(
                  "div",
                  {
                    staticClass: "mr-4",
                    style: _vm.$vuetify.breakpoint.mobile
                      ? "width: 100%; margin: 0"
                      : "",
                  },
                  [
                    _c("b", [_vm._v(_vm._s(_vm.$t("row-status")))]),
                    _c("span", [
                      _vm._v(
                        ": " +
                          _vm._s(
                            _vm.formatQuotationStatus(_vm.myQuotation.status)
                          )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    style: _vm.$vuetify.breakpoint.mobile ? "width: 100%;" : "",
                  },
                  [
                    _c("b", [_vm._v(_vm._s(_vm.$t("row-created-at")))]),
                    _c("span", [
                      _vm._v(
                        ": " +
                          _vm._s(
                            _vm.$filters.formatDatetime(
                              _vm.myQuotation.createdAt
                            )
                          )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    disabled:
                      _vm.myQuotation.status !== "QUOTED" || _vm.isLoading,
                    "x-large": "",
                    color: "primary",
                    width: "100%",
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialog.myQuotationConfirm = true
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-check")]),
                  _vm._v(
                    " " + _vm._s(_vm.$t("button-quotation-confirm")) + " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          !_vm.isLoading
            ? _c("VesselScheduleSearchResultListItemV2", {
                staticClass: "mb-4 w-100",
                attrs: {
                  schedules: _vm.vesselScheduleDetail,
                  "hide-booking": "",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: {
                "pr-4": !_vm.$vuetify.breakpoint.mobile,
                "mb-4": _vm.$vuetify.breakpoint.mobile,
              },
              attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 8 },
            },
            [
              _c("MyQuotationDetailContact", {
                staticStyle: { height: "100%" },
                attrs: { contact: _vm.contact },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 } },
            [_c("MyQuotationActionLog", { staticStyle: { height: "100%" } })],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "mb-4",
          staticStyle: { overflow: "hidden" },
          attrs: {
            ripple: false,
            width: "100%",
            height: "100%",
            rounded: "lg",
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-0 pt-0" },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "elevation-2",
                  attrs: {
                    centered: "",
                    grow: "",
                    "slider-size": "4",
                    height: "70px",
                    "background-color": "primary",
                    dark: "",
                  },
                  model: {
                    value: _vm.tabs,
                    callback: function ($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs",
                  },
                },
                [
                  _c(
                    "v-tab",
                    {
                      key: "1",
                      style: _vm.$vuetify.breakpoint.mobile
                        ? "width: 100%"
                        : "",
                      attrs: { ripple: false },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-garage"),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("title-commodity-surcharge")) + " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  staticClass: "mt-3",
                  model: {
                    value: _vm.tabs,
                    callback: function ($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs",
                  },
                },
                [
                  _c("v-tab-item", { key: "1", attrs: { eager: "" } }, [
                    _c(
                      "div",
                      { staticClass: "px-2" },
                      [
                        _c("MyQuotationCommodity"),
                        _c("MyQuotationSurchargeList"),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("MyQuotationCommentList"),
      _c("div", { staticStyle: { height: "150px" } }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550px" },
          model: {
            value: _vm.dialog.myQuotationConfirm,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "myQuotationConfirm", $$v)
            },
            expression: "dialog.myQuotationConfirm",
          },
        },
        [
          _vm.dialog.myQuotationConfirm
            ? _c("MyQuotationConfirm", {
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.myQuotationConfirm = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }