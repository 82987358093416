var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "lg" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            {
              staticClass: "text-left text-h6 font-weight-regular",
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "span",
                { staticClass: "d-flex align-center" },
                [
                  _c("v-icon", { attrs: { color: "primary", left: "" } }, [
                    _vm._v("mdi-information-outline"),
                  ]),
                  _c("span", { staticClass: "primary--text text-uppercase" }, [
                    _vm._v(_vm._s(_vm.$t("title-contact-infor"))),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "text-left mt-2" },
            [
              !_vm.isLoading
                ? _c("div", { staticClass: "mb-2" }, [
                    _c("div", [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.contact.name)),
                      ]),
                      _c("span", { staticClass: "mr-2" }, [
                        _vm._v(" | " + _vm._s(_vm.contact.phone)),
                      ]),
                    ]),
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.contact.emails.join(", ")) + " "),
                    ]),
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.contact.address) + " "),
                    ]),
                  ])
                : _vm._e(),
              _vm.isLoading
                ? [
                    _c(
                      "v-card",
                      { staticClass: "mb-2", attrs: { flat: "" } },
                      [
                        _c("v-skeleton-loader", {
                          staticClass: "mb-2",
                          attrs: { type: "text@4", boilerplate: false },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }