<template>
  <v-card
    style="overflow-y: auto"
    :ripple="false"
    width="100%"
    height="100%"
    max-height="550px"
    rounded="lg"
    class="pa-6"
  >
    <p class="text-left text-h6 font-weight-medium">
      <span class="d-flex align-center">
        <v-icon
          color="primary"
          left
          >mdi-history</v-icon
        >
        <span class="primary--text">LỊCH SỬ</span>
      </span>
    </p>

    <v-timeline
      align-top
      dense
    >
      <v-timeline-item
        v-for="(item, index) in myQuotationActionLogList"
        :key="item.id"
        :color="index === 0 ? 'success' : 'grey'"
        small
      >
        <v-row class="">
          <v-col>
            <span
              style="font-size: 14px"
              v-html="formatActionType(item)"
              >{{
            }}</span>
            <div
              class="text-caption text--secondary"
              style="font-size: 12px"
            >
              Lúc {{ $filters.formatTime(item.createdAt) }}
              {{ $filters.formatDate(item.createdAt) }}
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { formatActionType } from '@/services/quotation.service';

export default {
  computed: {
    ...mapState('vsl/quotation', ['myQuotationActionLogList']),
  },
  async created() {
    await this.fetchMyQuotationActionLogList({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions('vsl/quotation', ['fetchMyQuotationActionLogList']),
    formatActionType,
  },
};
</script>

<style></style>
