var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-text", [
        _c("p", { staticClass: "text-left text-h6 font-weight-regular" }, [
          _c(
            "span",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-icon",
                { attrs: { color: "primary", large: "", left: "" } },
                [_vm._v("mdi-form-select")]
              ),
              _c("span", { staticClass: "primary--text text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("title-surcharge"))),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticStyle: { "min-height": "250px" } },
          [
            !_vm.$vuetify.breakpoint.mobile
              ? _c(
                  "v-card",
                  {
                    staticClass: "mt-3",
                    attrs: { elevation: "0", rounded: "xl" },
                  },
                  [
                    _c(
                      "v-card-text",
                      { staticStyle: { "font-size": "11px" } },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "font-weight-bold text-uppercase",
                                attrs: { cols: "2" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("col-surcharge")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "font-weight-bold text-uppercase",
                                attrs: { cols: "2" },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("col-type")) + " ")]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "text-center font-weight-bold text-uppercase",
                                attrs: { cols: "1" },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("col-size")) + " ")]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "text-center font-weight-bold text-uppercase",
                                attrs: { cols: "2" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("col-payment")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "font-weight-bold text-uppercase",
                                attrs: { cols: "1" },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("col-price")) + " ")]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "text-center font-weight-bold text-uppercase",
                                attrs: { cols: "1" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("col-currency")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "text-center font-weight-bold text-uppercase",
                                attrs: { cols: "1" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("col-quantity")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "font-weight-bold text-uppercase",
                                attrs: { cols: "2" },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("col-total")) + " ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._l(_vm.myQuotationSurchargeList, function (item) {
              return _c(
                "v-card",
                {
                  key: item.id,
                  staticClass: "mt-2",
                  attrs: {
                    item: item,
                    color: "background",
                    elevation: "0",
                    rounded: "xl",
                  },
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "align-center",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-truncate",
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 2,
                              },
                            },
                            [
                              _vm.$vuetify.breakpoint.mobile
                                ? _c("b", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("col-surcharge")) +
                                        ": "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(_vm._s(item.surchargeName) + " "),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-truncate",
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 2,
                              },
                            },
                            [
                              _vm.$vuetify.breakpoint.mobile
                                ? _c("b", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("col-type")) + ": "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(_vm._s(item.surchargeItemName) + " "),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-truncate",
                              class: {
                                "text-center": !_vm.$vuetify.breakpoint.mobile,
                              },
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 1,
                              },
                            },
                            [
                              _vm.$vuetify.breakpoint.mobile
                                ? _c("b", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("col-size")) + ": "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(item.containerType) + " "),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-truncate",
                              class: {
                                "text-center": !_vm.$vuetify.breakpoint.mobile,
                              },
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 2,
                              },
                            },
                            [
                              _vm.$vuetify.breakpoint.mobile
                                ? _c("b", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("col-payment")) + ": "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                " " + _vm._s(_vm.formatType(item.type)) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-truncate",
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 1,
                              },
                            },
                            [
                              _vm.$vuetify.breakpoint.mobile
                                ? _c("b", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("col-price")) + ": "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatCurrency(item.price)) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-truncate",
                              class: {
                                "text-center": !_vm.$vuetify.breakpoint.mobile,
                              },
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 1,
                              },
                            },
                            [
                              _vm.$vuetify.breakpoint.mobile
                                ? _c("b", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("col-currency")) +
                                        ": "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(item.currencyCode) + " "),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-truncate",
                              class: {
                                "text-center": !_vm.$vuetify.breakpoint.mobile,
                              },
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 1,
                              },
                            },
                            [
                              _vm.$vuetify.breakpoint.mobile
                                ? _c("b", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("col-quantity")) +
                                        ": "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(item.quantity) + " "),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-truncate",
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 2,
                              },
                            },
                            [
                              _vm.$vuetify.breakpoint.mobile
                                ? _c("b", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("col-total")) + ": "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatCurrency(item.totalPrice)) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            _vm.myQuotationSurchargeList.length === 0 ? _c("NoData") : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }