<template>
  <v-card flat>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.mobile }">
      <v-row no-gutters>
        <p class="text-left text-h6 font-weight-regular">
          <span class="d-flex align-center">
            <v-icon
              color="primary"
              large
              left
              >mdi-package-variant</v-icon
            >
            <span class="primary--text text-uppercase">{{
              $t('title-commodity-info')
            }}</span>
          </span>
        </p>
        <v-spacer />
        <div
          v-if="isEditableMyQuotationCommodity"
          class="d-flex"
          :style="
            $vuetify.breakpoint.mobile
              ? `width: 100%; justify-content: space-between`
              : ``
          "
        >
          <v-btn
            class="mr-2"
            outlined
            :loading="isLoading"
            @click="toggleEdit"
          >
            <v-icon left>mdi-close</v-icon>
            {{ $t('button-cancel-edit') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="isLoading"
            @click="dialog.myQuotationCommodityCreate = true"
          >
            <v-icon left>mdi-plus</v-icon>
            {{
              $vuetify.breakpoint.mobile
                ? $t('button-add')
                : $t('button-add-commodity')
            }}
          </v-btn>
        </div>
        <div v-if="!isEditableMyQuotationCommodity">
          <v-btn
            color="orange darken-4"
            dark
            :disabled="
              myQuotation.status === 'APPROVED' ||
              myQuotation.status === 'CONFIRMED' ||
              myQuotation.status === 'CANCELED' ||
              isLoading
            "
            @click="toggleEdit"
          >
            <v-icon left>mdi-circle-edit-outline</v-icon>
            {{ $t('button-commodity-edit') }}
          </v-btn>
        </div>
        <div
          v-if="
            myQuotation.status === 'APPROVED' ||
            myQuotation.status === 'CONFIRMED'
          "
        >
          <v-menu
            open-on-hover
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="0"
                outlined
                small
                :loading="isDownloading.bookingNote"
              >
                <v-icon left> mdi-printer </v-icon>
                Tải booking note
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="file of files.bookingNote"
                :key="file.key"
                :ripple="false"
                @click="onDownload(file.key)"
              >
                <v-list-item-title>
                  <v-icon
                    v-if="file.format === 'docx'"
                    left
                    color="blue darken-3"
                  >
                    mdi-file-word-box
                  </v-icon>
                  <v-icon
                    v-if="file.format === 'pdf'"
                    left
                    color="red darken-3"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                  {{ `${file.title} (.${file.format})` }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            open-on-hover
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                v-bind="attrs"
                v-on="on"
                elevation="0"
                outlined
                small
                :loading="isDownloading.quotation"
              >
                <v-icon left> mdi-printer </v-icon>
                Tải quotation
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="file of files.quotation"
                :key="file.key"
                :ripple="false"
                @click="onDownload(file.key)"
              >
                <v-list-item-title>
                  <v-icon
                    v-if="file.format === 'docx'"
                    left
                    color="blue darken-3"
                  >
                    mdi-file-word-box
                  </v-icon>
                  <v-icon
                    v-if="file.format === 'pdf'"
                    left
                    color="red darken-3"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                  {{ `${file.title} (.${file.format})` }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-row>
      <v-row
        v-if="isEditableMyQuotationCommodity"
        no-gutters
        class="mt-4"
      >
        <v-spacer />
        <v-btn
          color="success"
          :disabled="isLoading"
          @click="dialog.myQuotationCommodityConfirmSendUpdate = true"
        >
          <v-icon left>mdi-progress-check</v-icon>
          {{ $t('title-send-update-commodity') }}
        </v-btn>
      </v-row>
      <MyQuotationCommodityList />
    </v-card-text>
    <v-dialog
      v-model="dialog.myQuotationCommodityCreate"
      max-width="850px"
      content-class="dialog"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <MyQuotationCommodityCreate
        v-if="dialog.myQuotationCommodityCreate"
        @close-dialog="dialog.myQuotationCommodityCreate = false"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.myQuotationCommodityConfirmSendUpdate"
      max-width="550px"
    >
      <MyQuotationCommodityConfirmSendUpdate
        v-if="dialog.myQuotationCommodityConfirmSendUpdate"
        @close-dialog="dialog.myQuotationCommodityConfirmSendUpdate = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import fileDownload from 'js-file-download';

import quotationApi from '@/api/vsl/quotation.api';
import { mapActions, mapMutations, mapState } from 'vuex';
import MyQuotationCommodityConfirmSendUpdate from './MyQuotationCommodityConfirmSendUpdate.vue';
import MyQuotationCommodityCreate from './MyQuotationCommodityCreate.vue';
import MyQuotationCommodityList from './MyQuotationCommodityList.vue';

export default {
  components: {
    MyQuotationCommodityList,
    MyQuotationCommodityCreate,
    MyQuotationCommodityConfirmSendUpdate,
  },
  data: () => ({
    isLoading: false,
    files: {
      quotation: [
        {
          title: 'Tiếng Việt',
          format: 'docx',
          key: 'quotation_vi_docx',
        },
        {
          title: 'Tiếng Anh',
          format: 'docx',
          key: 'quotation_en_docx',
        },
        {
          title: 'Tiếng Việt',
          format: 'pdf',
          key: 'quotation_vi_pdf',
        },
        {
          title: 'Tiếng Anh',
          format: 'pdf',
          key: 'quotation_en_pdf',
        },
      ],
      bookingNote: [
        {
          title: 'Tiếng Anh - Việt',
          format: 'docx',
          key: 'booking_note_docx',
        },
        {
          title: 'Tiếng Anh - Việt',
          format: 'pdf',
          key: 'booking_note_pdf',
        },
      ],
    },
    dialog: {
      myQuotationCommodityCreate: false,
      myQuotationCommodityConfirmSendUpdate: false,
    },
    isDownloading: {
      quotation: false,
      bookingNote: false,
    },
  }),
  computed: {
    ...mapState('vsl/quotation', [
      'myQuotation',
      'isEditableMyQuotationCommodity',
    ]),
  },
  async created() {},
  methods: {
    ...mapMutations('vsl/quotation', [
      'SET_IS_EDITABLE_MY_QUOTATION_COMMODITY',
    ]),
    ...mapActions('vsl/quotation', ['fetchMyQuotationCommodityList']),
    async toggleEdit() {
      if (this.isEditableMyQuotationCommodity) {
        try {
          this.isLoading = true;
          await this.fetchMyQuotationCommodityList({
            id: this.$route.params.id,
          });
        } finally {
          this.isLoading = false;
        }
      }
      this.SET_IS_EDITABLE_MY_QUOTATION_COMMODITY(
        !this.isEditableMyQuotationCommodity
      );
    },
    async onDownload(key) {
      // Booking Note
      if (key === 'booking_note_docx') {
        this.download(
          'booking_note',
          'docx',
          `booking_note_${this.myQuotation.code}.docx`
        );
      }
      if (key === 'booking_note_pdf') {
        this.download(
          'booking_note',
          'pdf',
          `booking_note_${this.myQuotation.code}.pdf`
        );
      }

      // Quotation
      if (key === 'quotation_vi_docx') {
        this.download(
          'quotation_vi',
          'docx',
          `quotation_${this.myQuotation.code}_vi.docx`
        );
      }
      if (key === 'quotation_en_docx') {
        this.download(
          'quotation_en',
          'docx',
          `quotation_${this.myQuotation.code}_en.docx`
        );
      }
      if (key === 'quotation_vi_pdf') {
        this.download(
          'quotation_vi',
          'pdf',
          `quotation_${this.myQuotation.code}_vi.pdf`
        );
      }
      if (key === 'quotation_en_pdf') {
        this.download(
          'quotation_en',
          'pdf',
          `quotation_${this.myQuotation.code}_en.pdf`
        );
      }
    },
    async download(type, format, filename) {
      try {
        if (type === 'quotation_en' || type === 'quotation_vi') {
          this.isDownloading.quotation = true;
        }
        if (type === 'booking_note') {
          this.isDownloading.bookingNote = true;
        }
        const res = await quotationApi.downloadQuotationAttachment(
          this.myQuotation.id,
          type,
          format
        );
        fileDownload(res.data, filename);
      } finally {
        if (type === 'quotation_en' || type === 'quotation_vi') {
          this.isDownloading.quotation = false;
        }
        if (type === 'booking_note') {
          this.isDownloading.bookingNote = false;
        }
      }
    },
  },
};
</script>

<style>
.dialog {
  max-width: 100vw;
}
</style>
