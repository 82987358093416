var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-6",
      attrs: { ripple: false, width: "100%", height: "100%", rounded: "lg" },
    },
    [
      _c("p", { staticClass: "text-left text-h6 font-weight-medium" }, [
        _c(
          "span",
          { staticClass: "d-flex align-center" },
          [
            _c("v-icon", { attrs: { color: "primary", left: "" } }, [
              _vm._v("mdi-information-outline"),
            ]),
            _c("span", { staticClass: "primary--text text-uppercase" }, [
              _vm._v(_vm._s(_vm.$t("title-general-info"))),
            ]),
          ],
          1
        ),
      ]),
      _c("div", [
        _c("div", { staticClass: "mt-2" }, [
          _c("ul", [
            _c("li", [
              _c("b", [_vm._v(_vm._s(_vm.$t("row-status")))]),
              _c("span", [
                _vm._v(
                  ": " +
                    _vm._s(_vm.formatQuotationStatus(_vm.myQuotation.status))
                ),
              ]),
            ]),
            _c("li", [
              _c("b", [_vm._v(_vm._s(_vm.$t("row-created-at")))]),
              _c("span", [
                _vm._v(
                  ": " + _vm._s(_vm.formatDatetime(_vm.myQuotation.createdAt))
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt-2" }, [
          _c("div", { staticClass: "text-h6 text--primary" }, [
            _vm._v(" " + _vm._s(_vm.$t("ship-schedules")) + " "),
          ]),
          _c("div", { staticClass: "mt-2" }, [
            _c("ul", [
              _c("li", [
                _c("b", [_vm._v(_vm._s(_vm.$t("row-quotation-code")))]),
                _c("span", [_vm._v(": " + _vm._s(_vm.myQuotation.code))]),
              ]),
              _c("li", [
                _c("b", [_vm._v(_vm._s(_vm.$t("row-vessel")))]),
                _c("span", [_vm._v(": " + _vm._s(_vm.myQuotation.vesselName))]),
              ]),
              _c("li", [
                _c("b", [_vm._v(_vm._s(_vm.$t("row-voyage")))]),
                _c("span", [_vm._v(": " + _vm._s(_vm.myQuotation.voyageCode))]),
              ]),
              _c("li", [
                _c("b", [_vm._v(_vm._s(_vm.$t("row-etd-eta")))]),
                _c("span", [
                  _vm._v(
                    ": " +
                      _vm._s(_vm.$filters.formatDate(_vm.myQuotation.etd)) +
                      " - " +
                      _vm._s(_vm.$filters.formatDate(_vm.myQuotation.eta))
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt-2" }, [
          _c("div", { staticClass: "text-h6 text--primary" }, [
            _vm._v(" " + _vm._s(_vm.$t("title-contact-infor")) + " "),
          ]),
          _c("div", { staticClass: "mt-2" }, [
            _c("ul", [
              _c("li", [
                _c("b", [_vm._v(_vm._s(_vm.$t("row-name")))]),
                _c("span", [_vm._v(": " + _vm._s(_vm.myQuotation.senderName))]),
              ]),
              _c("li", [
                _c("b", [_vm._v(_vm._s(_vm.$t("row-address")))]),
                _c("span", [
                  _vm._v(": " + _vm._s(_vm.myQuotation.senderAddress)),
                ]),
              ]),
              _c("li", [
                _c("b", [_vm._v("Email")]),
                _c("span", [
                  _vm._v(": " + _vm._s(_vm.myQuotation.senderEmail)),
                ]),
              ]),
              _c("li", [
                _c("b", [_vm._v(_vm._s(_vm.$t("row-phone")))]),
                _c("span", [
                  _vm._v(": " + _vm._s(_vm.myQuotation.senderPhone)),
                ]),
              ]),
              _c("li", [
                _c("b", [_vm._v(_vm._s(_vm.$t("row-note")))]),
                _c("span", { staticStyle: { "white-space": "pre-wrap" } }, [
                  _vm._v(": " + _vm._s(_vm.myQuotation.senderNote)),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }