var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-6",
      attrs: { ripple: false, width: "100%", height: "100%", rounded: "lg" },
    },
    [
      _c(
        "p",
        {
          staticClass:
            "text-left text-h6 font-weight-regula font-weight-mediumr",
        },
        [
          _c(
            "span",
            { staticClass: "d-flex align-center" },
            [
              _c("v-icon", { attrs: { color: "primary", left: "" } }, [
                _vm._v("mdi-comment-processing-outline"),
              ]),
              _c("span", { staticClass: "primary--text uppercase" }, [
                _vm._v(_vm._s(_vm.$t("title-comment"))),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {},
        [
          _c(
            "v-slide-y-transition",
            { attrs: { group: "", "leave-absolute": "", tag: "p" } },
            _vm._l(_vm.myQuotationCommentList, function (item) {
              return _c("CommentItem", {
                key: item.id,
                attrs: {
                  avatar: item.user.avatar,
                  name: item.user.name,
                  time: item.createdAt,
                  content: item.content,
                  files: item.files,
                },
              })
            }),
            1
          ),
          _c("CommentTextarea", {
            attrs: {
              avatar: _vm.myInfo.avatarLink,
              loading: _vm.isSendingComment,
            },
            on: { send: _vm.onSendComment },
            model: {
              value: _vm.comment,
              callback: function ($$v) {
                _vm.comment = $$v
              },
              expression: "comment",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }