<template>
  <v-card
    flat
    class="pa-4"
  >
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.mobile }">
      <v-row no-gutters>
        <p class="text-left text-h6 font-weight-regular">
          <span class="d-flex align-center">
            <v-icon
              color="primary"
              large
              left
              >mdi-hazard-lights</v-icon
            >
            <span
              class="primary--text text-uppercase"
              style="white-space: pre-wrap"
              >{{ $t('title-container-release-order-search') }}</span
            >
          </span>
        </p>
        <v-spacer />
        <div>
          <!-- <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" elevation="0">
                <v-icon left>
                  mdi-printer
                </v-icon>
                In
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="downloadFile('docx')" :ripple="false">
                <v-list-item-title>
                  <v-icon left color="blue darken-3">
                    mdi-file-word-box
                  </v-icon>
                  (.docx)
                  </v-list-item-title>
              </v-list-item>
              <v-list-item  @click="downloadFile('pdf')" :ripple="false">
                <v-list-item-title>
                  <v-icon left color="red darken-3">
                    mdi-file-pdf-box
                  </v-icon>
                  (.pdf)
                  </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
          <v-btn
            :disabled="item.status !== 'WAITING_USER_CONFIRM'"
            :loading="isLoadingConfirm"
            color="info"
            @click="onConfirmCro"
          >
            <v-icon left> mdi-email-check </v-icon>
            <span v-if="item.status === 'WAITING_SYSTEM_APPROVE'">{{
              $t('quotation-cro-status-3')
            }}</span>
            <span v-if="item.status === 'WAITING_USER_CONFIRM'">{{
              $t('button-confirm')
            }}</span>
            <span v-if="item.status === 'CONFIRMED'">
              {{ $t('quotation-cro-status-2') }}</span
            >
          </v-btn>
        </div>
      </v-row>

      <v-skeleton-loader
        v-if="isLoading"
        :boilerplate="false"
        type="text@5"
      />

      <div v-if="!isLoading">
        <ul class="pa-0">
          <li>
            <b>{{ $t('row-cro-no') }}</b> <span>: {{ item.no }}</span>
          </li>
          <li>
            <b>{{ $t('col-status') }}</b>
            <span>: {{ formatQuotationCroStatus(item.status) }}</span>
          </li>
          <li>
            <b>{{ $t('row-depot') }}</b>
            <span v-if="item.depot && item.depot.length > 0">
              : {{ item.depot }}
            </span>
            <span v-else> : {{ $t('none') }} </span>
          </li>
          <li>
            <b>Shipper</b>
            <span v-if="item.shipper && item.shipper.length > 0">
              : {{ item.shipper }}
            </span>
            <span v-else> : {{ $t('none') }} </span>
          </li>
          <li>
            <b>Ngày cấp lệnh</b>
            <span v-if="item.issueDate">
              : {{ $filters.formatDate(item.issueDate) }}
            </span>
            <span v-else> : {{ $t('none') }} </span>
          </li>
          <li>
            <b>Ngày hết hiệu lực</b>
            <span v-if="item.expiryDate">
              : {{ $filters.formatDate(item.expiryDate) }}
            </span>
            <span v-else> : {{ $t('none') }} </span>
          </li>
        </ul>
      </div>

      <div>
        <v-card
          class="mt-3"
          flat
          rounded="xl"
        >
          <v-card-text
            style="font-size: 11px"
            v-if="!$vuetify.breakpoint.mobile"
          >
            <v-row no-gutters>
              <v-col
                cols="3"
                class="font-weight-bold text-uppercase"
              >
                {{ $t('col-container-no') }}
              </v-col>
              <v-col
                cols="3"
                class="font-weight-bold text-uppercase"
              >
                {{ $t('col-container-type') }}
              </v-col>
              <v-col
                cols="6"
                class="text-left font-weight-bold text-uppercase"
              >
                {{ $t('col-note') }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <template v-if="!isLoading">
          <v-card
            v-for="item in myQuotationCro.containers"
            :key="item.id"
            :item="item"
            class="mt-2"
            color="background"
            elevation="0"
            rounded="xl"
          >
            <v-card-text>
              <v-row
                no-gutters
                class="align-center"
              >
                <v-col
                  :cols="$vuetify.breakpoint.mobile ? 12 : 3"
                  class="text-truncate"
                >
                  <b v-if="$vuetify.breakpoint.mobile">
                    {{ $t('col-container-no') }}:
                  </b>
                  <span
                    v-if="item.containerNo"
                    class="primary--text font-weight-bold"
                  >
                    {{ item.containerNo }}
                  </span>
                  <span
                    v-else
                    class="font-italic"
                  >
                    {{ $t('none') }}
                  </span>
                </v-col>
                <v-col
                  :cols="$vuetify.breakpoint.mobile ? 12 : 3"
                  class="text-truncate"
                >
                  <b v-if="$vuetify.breakpoint.mobile">
                    {{ $t('col-container-type') }}:
                  </b>

                  <span>{{ item.containerCode }}</span>
                </v-col>
                <v-col
                  :cols="$vuetify.breakpoint.mobile ? 12 : 6"
                  class="text-left"
                >
                  <b v-if="$vuetify.breakpoint.mobile">
                    {{ $t('col-note') }}:
                  </b>

                  <span v-if="item.remark">
                    {{ item.remark }}
                  </span>
                  <span
                    v-else
                    class="font-italic"
                  >
                    {{ $t('none') }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <template v-if="isLoading">
          <v-skeleton-loader
            :boilerplate="false"
            type="text@5"
          />
        </template>

        <NoData v-if="!isLoading && myQuotationCro.containers.length === 0" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import numeral from 'numeral';

import { mapActions, mapState } from 'vuex';
import NoData from '@/components/shared/local/base/NoData.vue';
export default {
  components: { NoData },
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    isLoading: false,
    isLoadingConfirm: false,
  }),
  computed: {
    ...mapState('vsl/quotationCro', ['myQuotationCro']),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchMyQuotationCro({ id: this.item.id });
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('vsl/quotation', ['fetchMyQuotationActionLogList']),
    ...mapActions('vsl/quotationCro', [
      'fetchMyQuotationCro',
      'fetchMyQuotationCroList',
      'confirmMyQuotationCro',
    ]),
    formatDate(value) {
      return dayjs(value).format('DD.MM.YYYY');
    },
    formatQuotationCroStatus(value) {
      if (value === 'CANCELED') {
        return this.$t('quotation-cro-status-1');
      }
      if (value === 'CONFIRMED') {
        return this.$t('quotation-cro-status-2');
      }
      if (value === 'WAITING_SYSTEM_APPROVE') {
        return this.$t('quotation-cro-status-3');
      }
      if (value === 'WAITING_USER_CONFIRM') {
        return this.$t('quotation-cro-status-4');
      }
    },
    downloadFile(type) {
      console.log('[LOG] : type', type);
    },
    async onConfirmCro() {
      try {
        this.isLoadingConfirm = true;
        await this.confirmMyQuotationCro({ id: this.item.id });
        await this.fetchMyQuotationCroList({
          quotationId: this.$route.params.id,
        });
        await this.fetchMyQuotationActionLogList({ id: this.$route.params.id });
        this.$message.success(this.$t('text-cro-confirm-success'));
        this.$emit('close-dialog');
      } finally {
        this.isLoadingConfirm = false;
      }
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  display: table;
  width: 100%;
  table-layout: fixed;
}

li {
  display: table-row;
}

b {
  display: table-cell;
  width: 150px;
}

span {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
