<template>
  <div>
    <v-row no-gutters>
      <p class="display-1 mt-2 text-uppercase">
        {{ $t('title-quotation-detail') }}
      </p>
    </v-row>
    <v-row
      no-gutters
      align="center"
      class="mb-4"
    >
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8">
        <div class="mr-4">
          <b>{{ $t('row-quotation-code') }}</b>
          <span>: {{ myQuotation.code }}</span>
        </div>
        <div class="d-flex flex-wrap">
          <div
            class="mr-4"
            :style="$vuetify.breakpoint.mobile ? 'width: 100%; margin: 0' : ''"
          >
            <b>{{ $t('row-status') }}</b>
            <span>: {{ formatQuotationStatus(myQuotation.status) }}</span>
          </div>
          <div :style="$vuetify.breakpoint.mobile ? 'width: 100%;' : ''">
            <b>{{ $t('row-created-at') }}</b>
            <span>: {{ $filters.formatDatetime(myQuotation.createdAt) }}</span>
          </div>
        </div>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
        <v-btn
          :loading="isLoading"
          :disabled="myQuotation.status !== 'QUOTED' || isLoading"
          x-large
          color="primary"
          width="100%"
          @click="dialog.myQuotationConfirm = true"
        >
          <v-icon left>mdi-check</v-icon>
          {{ $t('button-quotation-confirm') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <VesselScheduleSearchResultListItemV2
        v-if="!isLoading"
        :schedules="vesselScheduleDetail"
        hide-booking
        class="mb-4 w-100"
      />
    </v-row>
    <v-row
      no-gutters
      class="mb-4"
    >
      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 8"
        :class="{
          'pr-4': !$vuetify.breakpoint.mobile,
          'mb-4': $vuetify.breakpoint.mobile,
        }"
      >
        <MyQuotationDetailContact
          :contact="contact"
          style="height: 100%"
        />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
        <MyQuotationActionLog style="height: 100%" />
      </v-col>
    </v-row>

    <v-card
      class="mb-4"
      :ripple="false"
      width="100%"
      height="100%"
      rounded="lg"
      style="overflow: hidden"
    >
      <div class="px-0 pt-0">
        <v-tabs
          v-model="tabs"
          class="elevation-2"
          centered
          grow
          slider-size="4"
          height="70px"
          background-color="primary"
          dark
        >
          <v-tab
            :ripple="false"
            key="1"
            :style="$vuetify.breakpoint.mobile ? 'width: 100%' : ''"
          >
            <v-icon left>mdi-garage</v-icon>
            {{ $t('title-commodity-surcharge') }}
          </v-tab>
          <!-- <v-tab
            :ripple="false"
            key="2"
            :style="$vuetify.breakpoint.mobile ? 'width: 100%' : ''"
          >
            <v-icon left>mdi-hazard-lights</v-icon>
            <v-badge color="green" :content="`${myQuotationCroList.length}`">
              {{ $t('title-container-release-order-search') }}
            </v-badge>
          </v-tab> -->
        </v-tabs>
        <v-tabs-items
          v-model="tabs"
          class="mt-3"
        >
          <v-tab-item
            key="1"
            eager
          >
            <div class="px-2">
              <MyQuotationCommodity />
              <MyQuotationSurchargeList />
            </div>
          </v-tab-item>
          <!-- <v-tab-item key="2" eager>
            <div class="px-2">
              <MyQuotationCroList />
            </div>
          </v-tab-item> -->
        </v-tabs-items>
      </div>
    </v-card>

    <MyQuotationCommentList />
    <div style="height: 150px"></div>

    <v-dialog
      v-model="dialog.myQuotationConfirm"
      max-width="550px"
    >
      <MyQuotationConfirm
        v-if="dialog.myQuotationConfirm"
        @close-dialog="dialog.myQuotationConfirm = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import MyQuotationActionLog from './MyQuotationActionLog.vue';
import MyQuotationDetailGeneralInfo from './MyQuotationDetailGeneralInfo.vue';
import MyQuotationCommodity from './MyQuotationCommodity';
import MyQuotationSurchargeList from './MyQuotationSurchargeList.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import MyQuotationConfirm from './MyQuotationConfirm.vue';
import MyQuotationCroList from './MyQuotationCroList.vue';
import MyQuotationCommentList from './MyQuotationCommentList.vue';
import VesselScheduleSearchResultListItemV2 from '@/components/shared/local/vsl/VesselScheduleSearch/VesselScheduleSearchResultListItemV2.vue';
import MyQuotationDetailContact from './MyQuotationDetailContact.vue';
import {
  formatQuotationStatus,
  formatQuotationStatusColor,
} from '@/services/quotation.service';

export default {
  components: {
    MyQuotationDetailGeneralInfo,
    MyQuotationActionLog,
    MyQuotationCommodity,
    MyQuotationSurchargeList,
    MyQuotationConfirm,
    MyQuotationCroList,
    MyQuotationCommentList,
    VesselScheduleSearchResultListItemV2,
    MyQuotationDetailContact,
  },
  data: () => ({
    isLoading: false,
    dialog: {
      myQuotationConfirm: false,
    },
    tabs: 'tab-2',
    vesselScheduleDetail: {
      type: null,
      path: null,
      voyageCodes: [],
      pathSchedules: [],
    },
    contact: {
      name: '',
      phone: '',
      emails: [],
      address: '',
    },
  }),
  computed: {
    ...mapState('vsl/vesselSchedule', ['vesselSchedule']),
    ...mapState('vsl/quotation', ['myQuotation']),
    ...mapState('vsl/quotationCro', ['myQuotationCroList']),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchMyQuotation({ id: this.$route.params.id });

      const schedulesCodes = this.myQuotation.vesselScheduleCodes;
      this.vesselScheduleDetail.path = this.myQuotation.vesselSchedulePath;
      this.contact = {
        name: this.myQuotation.senderName,
        phone: this.myQuotation.senderPhone,
        emails: this.myQuotation.senderEmail.split(','),
        address: this.myQuotation.senderAddress,
      };
      console.log('[LOG] : this.contact', this.contact);

      for (const code of schedulesCodes) {
        await this.fetchVesselSchedule({ code });
        this.vesselScheduleDetail.voyageCodes.push(
          this.vesselSchedule.voyageCode
        );
        this.vesselScheduleDetail.pathSchedules.push(this.vesselSchedule);
      }
    } finally {
      this.isLoading = false;
    }
  },
  destroyed() {
    this.RESET();
  },
  methods: {
    ...mapMutations('vsl/quotation', ['RESET']),
    ...mapActions('vsl/quotation', ['fetchMyQuotation']),
    ...mapActions('vsl/vesselSchedule', ['fetchVesselSchedule']),
    formatQuotationStatus,
    formatQuotationStatusColor,
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-slide-group__content {
    flex-wrap: wrap;
    width: 100%;
  }
}
</style>
