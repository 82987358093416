var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticStyle: { "overflow-y": "auto" },
      attrs: {
        ripple: false,
        width: "100%",
        "max-height": "550px",
        rounded: "lg",
      },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            {
              staticClass: "text-left text-h6 font-weight-regular",
              attrs: { "no-gutters": "", align: "center" },
            },
            [
              _c(
                "span",
                { staticClass: "d-flex align-center" },
                [
                  _c("v-icon", { attrs: { color: "primary", left: "" } }, [
                    _vm._v("mdi-history"),
                  ]),
                  _c("span", { staticClass: "primary--text" }, [
                    _vm._v("LỊCH SỬ"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "grey darken-2",
                    small: "",
                    text: "",
                    ripple: false,
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialog.list = true
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("button-more")) + " "),
                  _c("v-icon", [_vm._v(" mdi-chevron-right ")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-timeline",
            { staticClass: "mt-2", attrs: { "align-top": "", dense: "" } },
            [
              _c(
                "v-timeline-item",
                { attrs: { color: "success", small: "" } },
                [
                  _vm.myQuotationActionLogList[0]
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", [
                            _c(
                              "span",
                              {
                                staticStyle: { "font-size": "14px" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.formatActionType(
                                      _vm.myQuotationActionLogList[0]
                                    )
                                  ),
                                },
                              },
                              [_vm._v(_vm._s())]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-caption text--secondary",
                                staticStyle: { "font-size": "12px" },
                              },
                              [
                                _vm._v(
                                  " Lúc " +
                                    _vm._s(
                                      _vm.$filters.formatTime(
                                        _vm.myQuotationActionLogList[0]
                                          .createdAt
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.$filters.formatDate(
                                        _vm.myQuotationActionLogList[0]
                                          .createdAt
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            scrollable: "",
            "max-width": "550px",
            transition: "dialog-transition",
          },
          model: {
            value: _vm.dialog.list,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "list", $$v)
            },
            expression: "dialog.list",
          },
        },
        [
          _vm.dialog.list
            ? _c("MyQuotationActionLogList", {
                staticStyle: { height: "80vh" },
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.list = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }