var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-4", attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { class: { "pa-0": _vm.$vuetify.breakpoint.mobile } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "p",
                { staticClass: "text-left text-h6 font-weight-regular" },
                [
                  _c(
                    "span",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "primary", large: "", left: "" } },
                        [_vm._v("mdi-hazard-lights")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "primary--text text-uppercase",
                          staticStyle: { "white-space": "pre-wrap" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("title-container-release-order-search")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-spacer"),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.item.status !== "WAITING_USER_CONFIRM",
                        loading: _vm.isLoadingConfirm,
                        color: "info",
                      },
                      on: { click: _vm.onConfirmCro },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(" mdi-email-check "),
                      ]),
                      _vm.item.status === "WAITING_SYSTEM_APPROVE"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("quotation-cro-status-3"))),
                          ])
                        : _vm._e(),
                      _vm.item.status === "WAITING_USER_CONFIRM"
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("button-confirm")))])
                        : _vm._e(),
                      _vm.item.status === "CONFIRMED"
                        ? _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("quotation-cro-status-2"))
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: { boilerplate: false, type: "text@5" },
              })
            : _vm._e(),
          !_vm.isLoading
            ? _c("div", [
                _c("ul", { staticClass: "pa-0" }, [
                  _c("li", [
                    _c("b", [_vm._v(_vm._s(_vm.$t("row-cro-no")))]),
                    _vm._v(" "),
                    _c("span", [_vm._v(": " + _vm._s(_vm.item.no))]),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v(_vm._s(_vm.$t("col-status")))]),
                    _c("span", [
                      _vm._v(
                        ": " +
                          _vm._s(_vm.formatQuotationCroStatus(_vm.item.status))
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v(_vm._s(_vm.$t("row-depot")))]),
                    _vm.item.depot && _vm.item.depot.length > 0
                      ? _c("span", [
                          _vm._v(" : " + _vm._s(_vm.item.depot) + " "),
                        ])
                      : _c("span", [
                          _vm._v(" : " + _vm._s(_vm.$t("none")) + " "),
                        ]),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v("Shipper")]),
                    _vm.item.shipper && _vm.item.shipper.length > 0
                      ? _c("span", [
                          _vm._v(" : " + _vm._s(_vm.item.shipper) + " "),
                        ])
                      : _c("span", [
                          _vm._v(" : " + _vm._s(_vm.$t("none")) + " "),
                        ]),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v("Ngày cấp lệnh")]),
                    _vm.item.issueDate
                      ? _c("span", [
                          _vm._v(
                            " : " +
                              _vm._s(
                                _vm.$filters.formatDate(_vm.item.issueDate)
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(" : " + _vm._s(_vm.$t("none")) + " "),
                        ]),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v("Ngày hết hiệu lực")]),
                    _vm.item.expiryDate
                      ? _c("span", [
                          _vm._v(
                            " : " +
                              _vm._s(
                                _vm.$filters.formatDate(_vm.item.expiryDate)
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(" : " + _vm._s(_vm.$t("none")) + " "),
                        ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "v-card",
                { staticClass: "mt-3", attrs: { flat: "", rounded: "xl" } },
                [
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "v-card-text",
                        { staticStyle: { "font-size": "11px" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase",
                                  attrs: { cols: "3" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("col-container-no")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase",
                                  attrs: { cols: "3" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("col-container-type")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-left font-weight-bold text-uppercase",
                                  attrs: { cols: "6" },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("col-note")) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.isLoading
                ? _vm._l(_vm.myQuotationCro.containers, function (item) {
                    return _c(
                      "v-card",
                      {
                        key: item.id,
                        staticClass: "mt-2",
                        attrs: {
                          item: item,
                          color: "background",
                          elevation: "0",
                          rounded: "xl",
                        },
                      },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "align-center",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-truncate",
                                    attrs: {
                                      cols: _vm.$vuetify.breakpoint.mobile
                                        ? 12
                                        : 3,
                                    },
                                  },
                                  [
                                    _vm.$vuetify.breakpoint.mobile
                                      ? _c("b", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("col-container-no")
                                              ) +
                                              ": "
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.containerNo
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "primary--text font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.containerNo) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "font-italic" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("none")) + " "
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-truncate",
                                    attrs: {
                                      cols: _vm.$vuetify.breakpoint.mobile
                                        ? 12
                                        : 3,
                                    },
                                  },
                                  [
                                    _vm.$vuetify.breakpoint.mobile
                                      ? _c("b", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("col-container-type")
                                              ) +
                                              ": "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(_vm._s(item.containerCode)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-left",
                                    attrs: {
                                      cols: _vm.$vuetify.breakpoint.mobile
                                        ? 12
                                        : 6,
                                    },
                                  },
                                  [
                                    _vm.$vuetify.breakpoint.mobile
                                      ? _c("b", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("col-note")) +
                                              ": "
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.remark
                                      ? _c("span", [
                                          _vm._v(
                                            " " + _vm._s(item.remark) + " "
                                          ),
                                        ])
                                      : _c(
                                          "span",
                                          { staticClass: "font-italic" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("none")) + " "
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.isLoading
                ? [
                    _c("v-skeleton-loader", {
                      attrs: { boilerplate: false, type: "text@5" },
                    }),
                  ]
                : _vm._e(),
              !_vm.isLoading && _vm.myQuotationCro.containers.length === 0
                ? _c("NoData")
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }