var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "" } },
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("div", [_vm._v(_vm._s(_vm.$t("title-commodity-add")))]),
              _c("v-spacer"),
              _c(
                "div",
                {
                  style: _vm.$vuetify.breakpoint.mobile
                    ? "width: 100%; text-align: right"
                    : "",
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { disabled: _vm.isLoading, text: "" },
                      on: { click: _vm.onCloseDialog },
                    },
                    [_vm._v(_vm._s(_vm.$t("button-close")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                        color: "success",
                      },
                      on: { click: _vm.onCreateCommodity },
                    },
                    [_vm._v(_vm._s(_vm.$t("button-add")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pb-6 pt-2" },
        [
          _c(
            "v-form",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
                    },
                    [
                      _c("CommodityPicker", {
                        staticClass: "pr-1",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "auto-select-first": "",
                          label: _vm.$t("label-commodity-picker"),
                          required: _vm.emptyLaden === "LADEN",
                          clearable: _vm.emptyLaden === "EMPTY",
                          value: _vm.commodityCode,
                        },
                        on: { change: _vm.onSelectCommodity },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3 },
                    },
                    [
                      _c("ContainerPicker", {
                        staticClass: "pr-1",
                        attrs: {
                          required: "",
                          outlined: "",
                          dense: "",
                          "auto-select-first": "",
                          label: _vm.$t("label-container-picker"),
                          value: _vm.containerCode,
                        },
                        on: { change: _vm.onSelectContainer },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3 },
                    },
                    [
                      _c("ContainerEmptyLadenPicker", {
                        staticClass: "pr-1",
                        attrs: {
                          required: "",
                          "auto-select-first": "",
                          label: _vm.$t("label-container-empty-laden-picker"),
                          value: _vm.emptyLaden,
                        },
                        on: { change: _vm.onSelectContainerEmptyLaden },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 2 },
                    },
                    [
                      _c("ContainerCocSocPicker", {
                        attrs: {
                          required: "",
                          "auto-select-first": "",
                          label: _vm.$t("label-coc-soc-picker"),
                          value: _vm.cocSoc,
                        },
                        on: { change: _vm.onSelectContainerCocSoc },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
                    },
                    [
                      _c("CommodityPrepaidPicker", {
                        staticClass: "pr-1",
                        attrs: {
                          required: "",
                          "auto-select-first": "",
                          label: _vm.$t("label-prepaid"),
                          value: _vm.prepaid,
                        },
                        on: { change: _vm.onSelectCommodityPrepaid },
                      }),
                    ],
                    1
                  ),
                  _vm.myQuotation.version === "v1"
                    ? [
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: _vm.$vuetify.breakpoint.mobile ? 12 : 8,
                            },
                          },
                          [
                            _c("BaseTextField", {
                              staticClass: "pr-1",
                              attrs: {
                                label: _vm.$t("label-quantity"),
                                required: "",
                                outlined: "",
                                dense: "",
                                type: "number",
                                min: "0",
                              },
                              model: {
                                value: _vm.quantity,
                                callback: function ($$v) {
                                  _vm.quantity = $$v
                                },
                                expression: "quantity",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4,
                            },
                          },
                          [
                            _c("BaseTextField", {
                              staticClass: "pr-1",
                              attrs: {
                                label: _vm.$t("label-container-new-quantity"),
                                required: "",
                                outlined: "",
                                dense: "",
                                type: "number",
                                min: "0",
                              },
                              model: {
                                value: _vm.containerNewQuantity,
                                callback: function ($$v) {
                                  _vm.containerNewQuantity = $$v
                                },
                                expression: "containerNewQuantity",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4,
                            },
                          },
                          [
                            _c("BaseTextField", {
                              staticClass: "pr-1",
                              attrs: {
                                required: "",
                                label: _vm.$t("label-container-mixed-quantity"),
                                outlined: "",
                                dense: "",
                                type: "number",
                                min: "0",
                              },
                              model: {
                                value: _vm.containerMixedQuantity,
                                callback: function ($$v) {
                                  _vm.containerMixedQuantity = $$v
                                },
                                expression: "containerMixedQuantity",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 6 },
                    },
                    [
                      _c("BaseTextField", {
                        staticClass: "pr-1",
                        attrs: {
                          label: _vm.$t("label-weight"),
                          "persistent-placeholder": "",
                          outlined: "",
                          dense: "",
                          type: "text",
                        },
                        model: {
                          value: _vm.weight,
                          callback: function ($$v) {
                            _vm.weight = $$v
                          },
                          expression: "weight",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 6 },
                    },
                    [
                      _c("BaseTextField", {
                        staticClass: "pl-1",
                        attrs: {
                          label: _vm.$t("label-size"),
                          "persistent-placeholder": "",
                          outlined: "",
                          dense: "",
                          type: "text",
                        },
                        model: {
                          value: _vm.size,
                          callback: function ($$v) {
                            _vm.size = $$v
                          },
                          expression: "size",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }