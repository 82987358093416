var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-4", attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { class: { "pa-0": _vm.$vuetify.breakpoint.mobile } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "p",
                { staticClass: "text-left text-h6 font-weight-regular" },
                [
                  _c(
                    "span",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "primary", large: "", left: "" } },
                        [_vm._v("mdi-hazard-lights")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "primary--text text-uppercase",
                          staticStyle: { "white-space": "pre-wrap" },
                        },
                        [_vm._v(_vm._s(_vm.$t("title-send-cro")))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-spacer"),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "info", loading: _vm.isSubmiting },
                      on: { click: _vm.onSubmitContainerCro },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(" mdi-email-send "),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("button-send-request")) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-card",
                { staticClass: "mt-3", attrs: { flat: "", rounded: "xl" } },
                [
                  _c(
                    "v-card-text",
                    { staticStyle: { "font-size": "11px" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold uppercase",
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 10 : 5,
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("col-container-type")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-left font-weight-bold uppercase",
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 2 : 7,
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("col-action")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.isLoading
                ? [
                    _c(
                      "v-scroll-y-transition",
                      {
                        attrs: { group: "", "leave-absolute": "", tag: "div" },
                      },
                      _vm._l(_vm.$v.containers.$each.$iter, function (v) {
                        return _c(
                          "v-card",
                          {
                            key: v.$model.id,
                            ref: v.$model.id,
                            refInFor: true,
                            staticClass: "mt-2",
                            attrs: {
                              item: v,
                              color: "background",
                              elevation: "0",
                              rounded: "xl",
                            },
                          },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "align-center",
                                    attrs: { "no-gutters": "" },
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: _vm.$vuetify.breakpoint.mobile
                                            ? 10
                                            : 5,
                                        },
                                      },
                                      [
                                        _c("ContainerPicker", {
                                          attrs: {
                                            "no-self-load-data": "",
                                            value: v.containerCode.$model,
                                            label: _vm.$t(
                                              "label-container-picker"
                                            ),
                                            outlined: "",
                                            dense: "",
                                            required: "",
                                            "hide-details": "",
                                            "error-messages":
                                              _vm.containerCodeErrors(v),
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return v.containerCode.$touch()
                                            },
                                            change: function ($event) {
                                              return _vm.onSelectContainerItem(
                                                $event,
                                                v.$model.id
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: {
                                          cols: _vm.$vuetify.breakpoint.mobile
                                            ? 2
                                            : 7,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-2",
                                            attrs: { icon: "", color: "error" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onRemoveContainerItem(
                                                  v.$model.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(" mdi-close "),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "my-4",
                        attrs: {
                          text: "",
                          ripple: false,
                          rounded: "",
                          color: "primary",
                        },
                        on: { click: _vm.onAddContainerItem },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(" mdi-plus "),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("button-add-container")) + " "
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }