var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-0" },
    [
      _c(
        "div",
        { staticStyle: { "min-height": "250px" } },
        [
          !_vm.$vuetify.breakpoint.mobile
            ? _c(
                "v-card",
                {
                  staticClass: "mt-3",
                  attrs: { elevation: "0", rounded: "xl" },
                },
                [
                  _c(
                    "v-card-text",
                    { staticStyle: { "font-size": "11px" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold text-uppercase",
                              attrs: { cols: "5" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("col-commodity")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold text-uppercase",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("col-price")) + " ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-center font-weight-bold text-uppercase",
                              attrs: { cols: "2" },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("col-action")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.filteredMyQuotationCommodityList, function (item) {
            return _c(
              "v-card",
              {
                key: item.id,
                staticClass: "mt-2",
                attrs: {
                  item: item,
                  color: "background",
                  elevation: "0",
                  rounded: "xl",
                },
              },
              [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "align-center",
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-truncate",
                            attrs: {
                              cols: _vm.$vuetify.breakpoint.mobile ? 12 : 5,
                            },
                          },
                          [
                            _c("ul", [
                              item.commodityCode
                                ? _c("li", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("row-commodity"))),
                                    ]),
                                    _c("span", [
                                      _vm._v(": " + _vm._s(item.name)),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("li", [
                                _c("b", [_vm._v("Container")]),
                                _c("span", [
                                  _vm._v(": " + _vm._s(item.containerCode)),
                                ]),
                              ]),
                              _c("li", [
                                _c("b", [_vm._v("COC/SOC")]),
                                _c("span", [
                                  _vm._v(": " + _vm._s(item.cocSoc)),
                                ]),
                              ]),
                              _c("li", [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("row-empty-laden"))),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    ": " +
                                      _vm._s(
                                        _vm.formatEmptyLaden(item.emptyLaden)
                                      )
                                  ),
                                ]),
                              ]),
                              item.containerMixedQuantity
                                ? _c("li", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("row-container-mixed-quantity")
                                        )
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        ": " +
                                          _vm._s(item.containerMixedQuantity) +
                                          " container"
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              item.containerNewQuantity
                                ? _c("li", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("row-container-new-quantity")
                                        )
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        ": " +
                                          _vm._s(item.containerNewQuantity) +
                                          " container"
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("li", [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("row-payment"))),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    ": " +
                                      _vm._s(_vm.formatPrepaid(item.prepaid))
                                  ),
                                ]),
                              ]),
                              item.weight && item.weight.length > 0
                                ? _c("li", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("row-weight"))),
                                    ]),
                                    _c("span", [
                                      _vm._v(": " + _vm._s(item.weight)),
                                    ]),
                                  ])
                                : _vm._e(),
                              item.size && item.size.length > 0
                                ? _c("li", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("row-size"))),
                                    ]),
                                    _c("span", [
                                      _vm._v(": " + _vm._s(item.size)),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("ul", [
                              _c("li", [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("row-unit-price"))),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    ": " +
                                      _vm._s(
                                        _vm.formatCurrency(item.price) +
                                          " " +
                                          item.currencyCode
                                      ) +
                                      " / container"
                                  ),
                                ]),
                              ]),
                              _c("li", [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("row-quantity"))),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    ": " + _vm._s(item.quantity) + " container"
                                  ),
                                ]),
                              ]),
                              _c("li", [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("row-total-price"))),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    ": " +
                                      _vm._s(
                                        _vm.formatCurrency(item.total) +
                                          " " +
                                          item.currencyCode
                                      )
                                  ),
                                ]),
                              ]),
                              item.systemUserNote
                                ? _c("li", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("row-note"))),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "white-space": "pre-wrap",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          ": " + _vm._s(item.systemUserNote)
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        !_vm.$vuetify.breakpoint.mobile
                          ? _c(
                              "v-col",
                              {
                                staticClass: "text-truncate",
                                attrs: { cols: "5" },
                              },
                              [
                                _c("ul", [
                                  _c("li", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("row-unit-price"))),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        ": " +
                                          _vm._s(
                                            _vm.formatCurrency(item.price) +
                                              " " +
                                              item.currencyCode
                                          ) +
                                          " / container"
                                      ),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("row-quantity"))),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        ": " +
                                          _vm._s(item.quantity) +
                                          " container"
                                      ),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("row-total-price"))),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        ": " +
                                          _vm._s(
                                            _vm.formatCurrency(item.total) +
                                              " " +
                                              item.currencyCode
                                          )
                                      ),
                                    ]),
                                  ]),
                                  item.systemUserNote
                                    ? _c("li", [
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.$t("row-note"))),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "white-space": "pre-wrap",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              ": " + _vm._s(item.systemUserNote)
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            class: {
                              "d-flex justify-end":
                                _vm.$vuetify.breakpoint.mobile,
                            },
                            attrs: {
                              cols: _vm.$vuetify.breakpoint.mobile ? 12 : 2,
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  color: "orange darken-4",
                                  disabled: !_vm.isEditableMyQuotationCommodity,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onUpdateItem(item)
                                  },
                                },
                              },
                              [_vm._v(" mdi-pencil ")]
                            ),
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  color: "error",
                                  disabled: !_vm.isEditableMyQuotationCommodity,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDeleteItem(item)
                                  },
                                },
                              },
                              [_vm._v(" mdi-delete ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.filteredMyQuotationCommodityList.length === 0
            ? _c("NoData", {
                attrs: {
                  rounded: "lg",
                  height: "348px",
                  text: _vm.$t("text-no-data"),
                },
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550px" },
          model: {
            value: _vm.dialog.myQuotationCommodityDelete,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "myQuotationCommodityDelete", $$v)
            },
            expression: "dialog.myQuotationCommodityDelete",
          },
        },
        [
          _vm.dialog.myQuotationCommodityDelete
            ? _c("MyQuotationCommodityDelete", {
                attrs: { item: _vm.selectedItem },
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.myQuotationCommodityDelete = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "850px",
            "content-class": "dialog",
            fullscreen: _vm.$vuetify.breakpoint.mobile,
          },
          model: {
            value: _vm.dialog.myQuotationCommodityUpdate,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "myQuotationCommodityUpdate", $$v)
            },
            expression: "dialog.myQuotationCommodityUpdate",
          },
        },
        [
          _vm.dialog.myQuotationCommodityUpdate
            ? _c("MyQuotationCommodityUpdate", {
                attrs: { item: _vm.selectedItem },
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.myQuotationCommodityUpdate = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }