var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "p",
                { staticClass: "text-left text-h6 font-weight-regular" },
                [
                  _c(
                    "span",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "primary", large: "", left: "" } },
                        [_vm._v("mdi-hazard-lights")]
                      ),
                      _c(
                        "span",
                        { staticClass: "primary--text text-uppercase" },
                        [_vm._v(_vm._s(_vm.$t("title-cro-list")))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "orange darken-4", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog.myQuotationCroCreate = true
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                  _vm._v(" " + _vm._s(_vm.$t("button-cro-create")) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "min-height": "250px" } },
            [
              !_vm.$vuetify.breakpoint.mobile
                ? _c(
                    "v-card",
                    {
                      staticClass: "mt-3",
                      attrs: { elevation: "0", rounded: "xl" },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticStyle: { "font-size": "11px" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("col-cro-no")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase",
                                  attrs: { cols: "3" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("col-status")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center font-weight-bold text-uppercase",
                                  attrs: { cols: "2" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("col-issue-date")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center font-weight-bold text-uppercase",
                                  attrs: { cols: "2" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("col-expiry-date")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.myQuotationCroList, function (item) {
                return _c(
                  "v-card",
                  {
                    key: item.id,
                    staticClass: "mt-2",
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      item: item,
                      color: "background",
                      elevation: "0",
                      rounded: "xl",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onShowDialogConfirmCro(item)
                      },
                    },
                  },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "align-center",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "text-truncate primary--text font-weight-bold",
                                attrs: {
                                  cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4,
                                },
                              },
                              [
                                _vm.$vuetify.breakpoint.mobile
                                  ? _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("col-cro-no")) +
                                          ": "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(item.no) + " "),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-truncate",
                                attrs: {
                                  cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3,
                                },
                              },
                              [
                                _vm.$vuetify.breakpoint.mobile
                                  ? _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("col-status")) +
                                          ": "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatQuotationCroStatus(item.status)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-truncate",
                                class: {
                                  "text-center":
                                    !_vm.$vuetify.breakpoint.mobile,
                                },
                                attrs: {
                                  cols: _vm.$vuetify.breakpoint.mobile ? 12 : 2,
                                },
                              },
                              [
                                _vm.$vuetify.breakpoint.mobile
                                  ? _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("col-issue-date")) +
                                          ": "
                                      ),
                                    ])
                                  : _vm._e(),
                                item.issueDate
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$filters.formatDate(
                                              item.issueDate
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", { staticClass: "font-italic" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("none")) + " "
                                      ),
                                    ]),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-truncate",
                                class: {
                                  "text-center":
                                    !_vm.$vuetify.breakpoint.mobile,
                                },
                                attrs: {
                                  cols: _vm.$vuetify.breakpoint.mobile ? 12 : 2,
                                },
                              },
                              [
                                _vm.$vuetify.breakpoint.mobile
                                  ? _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("col-expiry-date")) +
                                          ": "
                                      ),
                                    ])
                                  : _vm._e(),
                                item.expiryDate
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$filters.formatDate(
                                              item.expiryDate
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", { staticClass: "font-italic" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("none")) + " "
                                      ),
                                    ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm.myQuotationCroList.length === 0 ? _c("NoData") : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px" },
          model: {
            value: _vm.dialog.myQuotationCroDetail,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "myQuotationCroDetail", $$v)
            },
            expression: "dialog.myQuotationCroDetail",
          },
        },
        [
          _vm.dialog.myQuotationCroDetail
            ? _c("MyQuotationCroDetail", {
                attrs: { item: _vm.selectedCro },
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.myQuotationCroDetail = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px" },
          model: {
            value: _vm.dialog.myQuotationCroCreate,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "myQuotationCroCreate", $$v)
            },
            expression: "dialog.myQuotationCroCreate",
          },
        },
        [
          _vm.dialog.myQuotationCroCreate
            ? _c("MyQuotationCroCreate", {
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.myQuotationCroCreate = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }