var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-6",
      staticStyle: { "overflow-y": "auto" },
      attrs: {
        ripple: false,
        width: "100%",
        height: "100%",
        "max-height": "550px",
        rounded: "lg",
      },
    },
    [
      _c("p", { staticClass: "text-left text-h6 font-weight-medium" }, [
        _c(
          "span",
          { staticClass: "d-flex align-center" },
          [
            _c("v-icon", { attrs: { color: "primary", left: "" } }, [
              _vm._v("mdi-history"),
            ]),
            _c("span", { staticClass: "primary--text" }, [_vm._v("LỊCH SỬ")]),
          ],
          1
        ),
      ]),
      _c(
        "v-timeline",
        { attrs: { "align-top": "", dense: "" } },
        _vm._l(_vm.myQuotationActionLogList, function (item, index) {
          return _c(
            "v-timeline-item",
            {
              key: item.id,
              attrs: { color: index === 0 ? "success" : "grey", small: "" },
            },
            [
              _c(
                "v-row",
                {},
                [
                  _c("v-col", [
                    _c(
                      "span",
                      {
                        staticStyle: { "font-size": "14px" },
                        domProps: {
                          innerHTML: _vm._s(_vm.formatActionType(item)),
                        },
                      },
                      [_vm._v(_vm._s())]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "text-caption text--secondary",
                        staticStyle: { "font-size": "12px" },
                      },
                      [
                        _vm._v(
                          " Lúc " +
                            _vm._s(_vm.$filters.formatTime(item.createdAt)) +
                            " " +
                            _vm._s(_vm.$filters.formatDate(item.createdAt)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }