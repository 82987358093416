var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { class: { "pa-0": _vm.$vuetify.breakpoint.mobile } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "p",
                { staticClass: "text-left text-h6 font-weight-regular" },
                [
                  _c(
                    "span",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "primary", large: "", left: "" } },
                        [_vm._v("mdi-package-variant")]
                      ),
                      _c(
                        "span",
                        { staticClass: "primary--text text-uppercase" },
                        [_vm._v(_vm._s(_vm.$t("title-commodity-info")))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-spacer"),
              _vm.isEditableMyQuotationCommodity
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      style: _vm.$vuetify.breakpoint.mobile
                        ? `width: 100%; justify-content: space-between`
                        : ``,
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { outlined: "", loading: _vm.isLoading },
                          on: { click: _vm.toggleEdit },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-close"),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("button-cancel-edit")) + " "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", disabled: _vm.isLoading },
                          on: {
                            click: function ($event) {
                              _vm.dialog.myQuotationCommodityCreate = true
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.breakpoint.mobile
                                  ? _vm.$t("button-add")
                                  : _vm.$t("button-add-commodity")
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isEditableMyQuotationCommodity
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "orange darken-4",
                            dark: "",
                            disabled:
                              _vm.myQuotation.status === "APPROVED" ||
                              _vm.myQuotation.status === "CONFIRMED" ||
                              _vm.myQuotation.status === "CANCELED" ||
                              _vm.isLoading,
                          },
                          on: { click: _vm.toggleEdit },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-circle-edit-outline"),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("button-commodity-edit")) + " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myQuotation.status === "APPROVED" ||
              _vm.myQuotation.status === "CONFIRMED"
                ? _c(
                    "div",
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "open-on-hover": "", "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              elevation: "0",
                                              outlined: "",
                                              small: "",
                                              loading:
                                                _vm.isDownloading.bookingNote,
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v(" mdi-printer "),
                                        ]),
                                        _vm._v(" Tải booking note "),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4032617753
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.files.bookingNote, function (file) {
                              return _c(
                                "v-list-item",
                                {
                                  key: file.key,
                                  attrs: { ripple: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDownload(file.key)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      file.format === "docx"
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                left: "",
                                                color: "blue darken-3",
                                              },
                                            },
                                            [_vm._v(" mdi-file-word-box ")]
                                          )
                                        : _vm._e(),
                                      file.format === "pdf"
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                left: "",
                                                color: "red darken-3",
                                              },
                                            },
                                            [_vm._v(" mdi-file-pdf-box ")]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${file.title} (.${file.format})`
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: { "open-on-hover": "", "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              elevation: "0",
                                              outlined: "",
                                              small: "",
                                              loading:
                                                _vm.isDownloading.quotation,
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v(" mdi-printer "),
                                        ]),
                                        _vm._v(" Tải quotation "),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1117941543
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.files.quotation, function (file) {
                              return _c(
                                "v-list-item",
                                {
                                  key: file.key,
                                  attrs: { ripple: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDownload(file.key)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      file.format === "docx"
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                left: "",
                                                color: "blue darken-3",
                                              },
                                            },
                                            [_vm._v(" mdi-file-word-box ")]
                                          )
                                        : _vm._e(),
                                      file.format === "pdf"
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                left: "",
                                                color: "red darken-3",
                                              },
                                            },
                                            [_vm._v(" mdi-file-pdf-box ")]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${file.title} (.${file.format})`
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.isEditableMyQuotationCommodity
            ? _c(
                "v-row",
                { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", disabled: _vm.isLoading },
                      on: {
                        click: function ($event) {
                          _vm.dialog.myQuotationCommodityConfirmSendUpdate = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-progress-check"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("title-send-update-commodity")) +
                          " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("MyQuotationCommodityList"),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "850px",
            "content-class": "dialog",
            fullscreen: _vm.$vuetify.breakpoint.mobile,
          },
          model: {
            value: _vm.dialog.myQuotationCommodityCreate,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "myQuotationCommodityCreate", $$v)
            },
            expression: "dialog.myQuotationCommodityCreate",
          },
        },
        [
          _vm.dialog.myQuotationCommodityCreate
            ? _c("MyQuotationCommodityCreate", {
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.myQuotationCommodityCreate = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550px" },
          model: {
            value: _vm.dialog.myQuotationCommodityConfirmSendUpdate,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "myQuotationCommodityConfirmSendUpdate", $$v)
            },
            expression: "dialog.myQuotationCommodityConfirmSendUpdate",
          },
        },
        [
          _vm.dialog.myQuotationCommodityConfirmSendUpdate
            ? _c("MyQuotationCommodityConfirmSendUpdate", {
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.myQuotationCommodityConfirmSendUpdate = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }